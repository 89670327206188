import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="time-now"
export default class extends Controller {
  apply(e) {
    e.preventDefault();

    const input = e.target.parentNode.parentNode.querySelector('input');

    input.value = (new Date()).toString().split(' ')[4];

    if (input.form) {
      input.form.dispatchEvent(new Event('change'));
      input.form.dispatchEvent(new Event('focusout'));
    }
  }
}
